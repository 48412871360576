<template>
<v-container fluid fill-height>
    <v-card :class="`align-self-stretch ` + ($vuetify.breakpoint.mdAndUp ? `pa-4 ` : `pa-0` )" :flat="$vuetify.breakpoint.mdAndUp ? true : false" :elevation="$vuetify.breakpoint.mdAndUp ? `1` : `0`" width="100%">
        <v-card-title class="px-0">
            <h1>Мій профайл</h1>
        </v-card-title>
        <!-- <v-toolbar flat>
            <v-btn icon @click="getData()" title="Оновити">
                <v-icon color="grey">mdi-cached</v-icon>
            </v-btn>
        </v-toolbar> -->
        <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
            <v-container fluid class="pa-0">
                <v-row>
                    <v-col cols="12" sm="12" md="6" class="d-flex">

                        <v-card width="100%" elevation="6" class="align-self-stretch">
                            <v-card-title class="subtitle-1 grey lighten-2 mb-4">
                                <v-icon class="mr-4">mdi-card-account-details</v-icon>
                                ПІБ: <span class="font-weight-bold ml-4">{{f.contactName}}</span>
                                <v-spacer></v-spacer>
                            </v-card-title>
                            <v-card-text>
                                <v-text-field prepend-icon="mdi-domain" v-model="f.clientName" label="Компанія" readonly class="mb-4"></v-text-field>
                                <!-- <v-text-field prepend-icon="mdi-account" v-model="f.contactName" label="ПІБ" readonly></v-text-field> -->
                                <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field v-model="f.contactBirthday" outlined label="Дата нарождення" prepend-icon="mdi-calendar" readonly v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker first-day-of-week="1" v-model="f.contactBirthday" @input="menu = false"></v-date-picker>
                                </v-menu>
                                <validation-provider v-slot="{ errors, valid }" rules="required|email">
                                    <v-text-field v-model="f.contactEmail" outlined prepend-icon="mdi-email" :error-messages="errors" label="Email"></v-text-field>
                                </validation-provider>
                                <validation-provider v-slot="{ errors, valid }" rules="required|tel">
                                    <v-text-field prepend-icon="mdi-cellphone-text" outlined :loading="checkLoading" v-model="f.contactPhone" label="Основний тел. (для SMS)" :error-messages="errors" placeholder="+38(000) 000 - 0000" v-mask="maskTel"></v-text-field>
                                </validation-provider>
                                <v-text-field prepend-icon="mdi-file-phone-outline" outlined v-model="f.contactTel" label="Додаткові тел. номери"></v-text-field>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" class="d-flex flex-column">
                        <v-card width="100%" elevation="6" class="mb-6 align-self-stretch">
                            <v-card-title class="subtitle-1 grey lighten-2 mb-4">
                                <v-icon class="mr-4">mdi-cellphone-message</v-icon>
                                Керування підпискою
                                <v-spacer></v-spacer>
                            </v-card-title>
                            <v-card-text>
                                <validation-provider v-slot="{ errors, valid }" rules="required">
                                    <v-switch v-model="f.contactSMS" hint="Трекінг доставок по SMS" persistent-hint :label="(f.contactSMS ? 'Вкл.' : 'Вимк.' )"></v-switch>
                                </validation-provider>
                                <validation-provider v-slot="{ errors, valid }" rules="required">
                                    <v-switch v-model="f.maillist" hint="Отримувати e-mail розсилки" persistent-hint :label="(f.maillist ? 'Вкл.' : 'Вимк.' )"></v-switch>
                                </validation-provider>
                            </v-card-text>
                        </v-card>
                        <v-card width="100%" elevation="6" class="align-self-stretch">
                            <v-card-title class="subtitle-1 grey lighten-2 mb-4">
                                <v-icon class="mr-4">mdi-key</v-icon>
                                Доступ на B2B-портал
                                <v-spacer></v-spacer>
                            </v-card-title>
                            <v-card-text>
                                <v-text-field v-model="f.login" label="Логін" readonly></v-text-field>
                                <v-card-subtitle>Змінити пароль:</v-card-subtitle>
                                <v-text-field prepend-icon="mdi-lock-outline" outlined hint="Мiнімум 8 знакiв" counter @click:append="show1 = !show1" :type="show1 ? 'text' : 'password'" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" v-model="f.pass1" label="Пароль" :error-messages="passErrors"></v-text-field>
                                <v-text-field prepend-icon="mdi-lock-outline" outlined hint="Мiнімум 8 знакiв" counter @click:append="show2 = !show2" :type="show2 ? 'text' : 'password'" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" v-model="f.pass2" label="Підтвердження пароля" error-messages=""></v-text-field>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
            <v-card-actions class="px-0 my-6">
                <v-btn color="primary" @click="submitForm()" :width="$vuetify.breakpoint.mdAndUp ? `auto` : `100%`">Зберегти</v-btn>
            </v-card-actions>
        </ValidationObserver>
    </v-card>

</v-container>
</template>

<script>
import {
    ValidationObserver,
    ValidationProvider
} from "vee-validate"

import {
    mapGetters,
    mapActions
} from 'vuex'

import {
    mask
} from 'vue-the-mask'

export default {
    components: {
        ValidationProvider,
        ValidationObserver
    },
    directives: {
        mask
    },
    data: () => ({
        f: {},
        maskTel: '+38(###) ###-####',
        checkLoading: false,
        menu: false,
        show1: false,
        show2: false,
        passErrors: ''
    }),
    props: {},
    methods: {
        ...mapGetters(['userInfo']),
        ...mapActions(['getContactInfo', 'setSnackbar', 'sendContactInfo']),
        getData() {
            this.getContactInfo()
                .then(data => this.f = data)
        },
        async submitForm() {
            const isValid = await this.$refs.observer.validate();

            if (!isValid || !this.checkPass()) {
                this.setSnackbar(['error', 'Заповнiть усi необхiднi поля'])
            } else {
                let contactPhone = this.f.contactPhone.replace('(', '').replace(')', '').replace('-', ' ').replace(/0/, '0 ')
                this.$set(this.f, 'contactPhone', contactPhone)

                this.sendContactInfo(this.f)
                    .then(() => {
                        this.setSnackbar(['success', 'Успiшно збережено'])
                    })
                    .catch(error => {
                        this.setSnackbar(['error', error])
                    })
            }
        },
        checkPass() {
            this.passErrors = ''
            if (this.f.pass1 || this.f.pass2) {
                if (this.f.pass1 != this.f.pass2) {
                    this.passErrors = 'Паролі не співпадають'
                    return false
                }
                if (this.f.pass1.length < 8 || this.f.pass2.length < 8) {
                    this.passErrors = 'Пароль повинен бути більше 7 символів'
                    return false
                }
            }
            return true
        }
    },
    created() {
        if (this.userInfo().is_wholesale_onbehalf_ok) {
            // this.$router.push('/403')
        }
        this.getData()
    },
    computed: {
        contactInfo() {
            return this.$store.state.main.contactInfo
        },
    }
}
</script>

<style>
</style>
