<template>
    <ContactInfoPage />
</template>

<script>
import {
    mapActions
} from 'vuex'

import ContactInfoPage from '../components/ContactInfoPage.vue'

export default {
    data: () => ({
    }),
    components: {
        ContactInfoPage
    },
    methods: {
        ...mapActions(['getUserInfo'])
    },
    mounted() {
        //this.getUserInfo(this)
    }
}
</script>

